import React from 'react';
import './Client.css';

function Client() {
  return (
    <div className="client-container">
      <div className="client-box top-left">
        <a href="https://support.conquer-it.pt/" className="box-link">Support Ticket</a>
      </div>
      <div className="client-box top-right">Box 2</div>
      <div className="client-box bottom-left">Box 3</div>
      <div className="client-box bottom-right">Box 4</div>
    </div>
  );
}

export default Client;
