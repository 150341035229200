// src/ContactForm.js
import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { useForm, ValidationError } from '@formspree/react';
import './TSform'; // Ensure this line imports the translations
import './form.css';

function ContactForm() {
  const { t } = useTranslation('form');
  const [countryOptions, setCountryOptions] = useState([]);
  const [prefixOptions, setPrefixOptions] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedPrefix, setSelectedPrefix] = useState(null);
  const [contactNumber, setContactNumber] = useState("");
  const [contactName, setContactName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    fetch('https://restcountries.com/v3.1/all')
      .then(response => response.json())
      .then(data => {
        const sortedCountries = data.sort((a, b) =>
          a.name.common.localeCompare(b.name.common)
        );

        setCountryOptions(sortedCountries.map(country => ({
          label: country.name.common,
          value: country.cca2
        })));

        const sortedPrefixes = sortedCountries.flatMap(country => (
          country.idd && country.idd.root && country.idd.suffixes ? country.idd.suffixes.map(suffix => ({
            label: `${country.idd.root}${suffix} (${country.name.common})`,
            value: `${country.idd.root}${suffix}`
          })) : []
        )).sort((a, b) => a.label.localeCompare(b.label));

        setPrefixOptions(sortedPrefixes);
      })
      .catch(error => console.error('Error fetching countries:', error));
  }, []);

  const [state, handleSubmit] = useForm("yourFormspreeID");

  return (
    <div className="App">
      <div className="form-container">
        <h1>{t('CONTACT_FORM_TITLE')}</h1>
        {state.succeeded ? (
          <p>{t('FORM_SUBMISSION_SUCCESS')}</p>
        ) : (
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="contactName">{t('CONTACT_NAME_LABEL')}:</label>
              <input
                type="text"
                id="contactName"
                name="contactName"
                value={contactName}
                onChange={(e) => setContactName(e.target.value)}
                required
                className="input-field"
              />
              <ValidationError prefix="Contact Name" field="contactName" errors={state.errors} />
            </div>
            <div className="form-group">
              <label htmlFor="companyName">{t('COMPANY_NAME_LABEL')}:</label>
              <input
                type="text"
                id="companyName"
                name="companyName"
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
                className="input-field"
              />
              <ValidationError prefix="Company Name" field="companyName" errors={state.errors} />
            </div>
            <div className="form-group">
              <label htmlFor="email">{t('EMAIL_LABEL')}:</label>
              <input
                type="email"
                id="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className="input-field"
              />
              <ValidationError prefix="Email" field="email" errors={state.errors} />
            </div>
            <div className="form-group">
              <label htmlFor="country">{t('COUNTRY_LABEL')}:</label>
              <Select
                id="country"
                name="country"
                options={countryOptions}
                value={selectedCountry}
                onChange={setSelectedCountry}
                placeholder={t('SELECT_COUNTRY_PLACEHOLDER')}
                isClearable
                className="select-field"
              />
            </div>
            <div className="form-group contact-number-group">
              <label htmlFor="contactPrefix">{t('CONTACT_NUMBER_LABEL')}:</label>
              <div className="contact-number-wrapper">
                <Select
                  id="contactPrefix"
                  name="contactPrefix"
                  options={prefixOptions}
                  value={selectedPrefix}
                  onChange={setSelectedPrefix}
                  placeholder={t('PREFIX_PLACEHOLDER')}
                  isClearable
                  className="select-field prefix-select"
                />
                <input
                  type="text"
                  id="contactNumber"
                  name="contactNumber"
                  value={contactNumber}
                  onChange={(e) => setContactNumber(e.target.value)}
                  required
                  className="input-field contact-number-input"
                />
              </div>
              <ValidationError prefix="Contact Number" field="contactNumber" errors={state.errors} />
            </div>
            <div className="form-group">
              <label htmlFor="message">{t('MESSAGE_LABEL')}:</label>
              <textarea
                id="message"
                name="message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                className="input-field"
              />
              <ValidationError prefix="Message" field="message" errors={state.errors} />
            </div>
            <button type="submit" disabled={state.submitting} className="submit-button">
              {t('SUBMIT_BUTTON')}
            </button>
            {state.errors && state.errors.length > 0 && (
              <div className="error-message">{t('FORM_SUBMISSION_ERROR')}</div>
            )}
          </form>
        )}
      </div>
      <div className="contact-options">
        <a href="https://api.whatsapp.com/send?phone=351926841701&text=Boa%20tarde%20T%C3%A9cnicos.%20Gostaria%20de%20saber%20mais%20informações" target="_blank" rel="noopener noreferrer" className="contact-option">
          <img src={process.env.PUBLIC_URL + '/IMG/WhatsApp_logo.png'} alt='Whatsapp' />  
        </a>
        <a href="https://t.me/+gGjd-C7TBrIyNDY0" target="_blank" rel="noopener noreferrer" className="contact-option">
          <img src={process.env.PUBLIC_URL + '/IMG/Telegram-logo.png'} alt='Telegram' />  
        </a>
        <a href="https://discord.gg/ReyMJkdE4Y" target="_blank" rel="noopener noreferrer" className="contact-option">
          <img src={process.env.PUBLIC_URL + '/IMG/discord.png'} alt='Discord' />  
        </a>
      </div>
    </div>
  );
}

export default ContactForm;
