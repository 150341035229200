// src/AboutUs.js
import React from 'react';
import { useTranslation } from 'react-i18next';
import './TSabout'; // Ensure this line imports the translations
import './AboutUs.css';

const AboutUs = () => {
  const { t } = useTranslation('about'); // Make sure 'about' namespace is used

  return (
    <div className="about-us-container">
      <h1>{t('ABOUT_US_TITLE')}</h1>
      <p>{t('ABOUT_US_CONTENT')}</p>

      <h2>{t('IMAGE_BRANDING_TITLE')}</h2>
      <p>{t('IMAGE_BRANDING_CONTENT')}</p>
      <a href={process.env.PUBLIC_URL + 'CONQUER-IT.pdf'} target="_blank" rel="noopener noreferrer">
        {t('LINK_TO_BRAND_GUIDE')}
      </a>

      <h2>{t('BUSINESS_MARKETING_PLAN_TITLE')}</h2>
      <p>{t('BUSINESS_MARKETING_PLAN_CONTENT')}</p>
      <a href={process.env.PUBLIC_URL + '/PDF/business-plan.pdf'} target="_blank" rel="noopener noreferrer">
        {t('LINK_TO_BUSINESS_PLAN')}
      </a>
    </div>
  );
};

export default AboutUs;
