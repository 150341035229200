// src/Services.js
import React from 'react';
import { useTranslation } from 'react-i18next';
import './services.css';
import './TSservices';  // Ensure this line imports the translations

const Services = () => {
  const { t } = useTranslation('services');  // Specify the namespace 'services'

  return (
    <div className="services-container">
      <h1>{t('SERVICES_TITLE')}</h1>
      <p>{t('SERVICES_WELCOME')}</p>
      <div className="services-list">
        <div className="service-item">
          <img src="placeholder-image-url-cloud" alt="Cloud Services" />
          <h2>{t('CLOUD_SERVICES_TITLE')}</h2>
          <p>{t('CLOUD_SERVICES_DESCRIPTION')}</p>
        </div>
        <div className="service-item">
          <img src="placeholder-image-url-server" alt="On-Premises Server Solutions" />
          <h2>{t('SERVER_SOLUTIONS_TITLE')}</h2>
          <p>{t('SERVER_SOLUTIONS_DESCRIPTION')}</p>
        </div>
        <div className="service-item">
          <img src="placeholder-image-url-hosting" alt="Web & App Hosting" />
          <h2>{t('HOSTING_SERVICES_TITLE')}</h2>
          <p>{t('HOSTING_SERVICES_DESCRIPTION')}</p>
        </div>
        <div className="service-item">
          <img src="placeholder-image-url-webdesign" alt="Web Design" />
          <h2>{t('WEB_DESIGN_TITLE')}</h2>
          <p>{t('WEB_DESIGN_DESCRIPTION')}</p>
        </div>
        <div className="service-item">
          <img src="placeholder-image-url-database" alt="Database Services" />
          <h2>{t('DATABASE_SERVICES_TITLE')}</h2>
          <p>{t('DATABASE_SERVICES_DESCRIPTION')}</p>
        </div>
      </div>
    </div>
  );
};

export default Services;
