import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// The translations
const resources = {
  en: {
    translation: {
      "OP1": "Home",
      "OP2": "Services",
      "OP3": "Contact Form",
      "OP4": "More Options",
      "OP4.1": "Sub Option 1",
      "OP4.2": "Team",
      "OP4.3": "About Us",
      "CONTACT_US": "Contact Us",
      "CLIENT_AREA": "Client Area",
      "COMPANY": "Company Ltd"
    }
  },
  pt: {
    translation: {
      "OP1": "Home",
      "OP2": "Serviços",
      "OP3": "Formulário de Contato",
      "OP4": "Mais Opções",
      "OP4.1": "Sub Opção 1",
      "OP4.2": "Equipa",
      "OP4.3": "Sobre Nós",
      "CONTACT_US": "Contacte-nos",
      "CLIENT_AREA": "Área do Cliente",
      "COMPANY": "Empresa Ltda"
    }
  },
  fr: {
    translation: {
      "OP1": "Accueil",
      "OP2": "Services",
      "OP3": "Formulaire de Contact",
      "OP4": "Plus d'Options",
      "OP4.1": "Sous-option 1",
      "OP4.2": "Équipe",
      "OP4.3": "À Propos",
      "CONTACT_US": "Contactez-nous",
      "CLIENT_AREA": "Espace Client",
      "COMPANY": "Entreprise Ltd"
    }
  },
  de: {
    translation: {
      "OP1": "Startseite",
      "OP2": "Dienstleistungen",
      "OP3": "Kontaktformular",
      "OP4": "Mehr Optionen",
      "OP4.1": "Unteroption 1",
      "OP4.2": "Team",
      "OP4.3": "Über Uns",
      "CONTACT_US": "Kontaktieren Sie uns",
      "CLIENT_AREA": "Kundenbereich",
      "COMPANY": "Firma Ltd"
    }
  },
  es: {
    translation: {
      "OP1": "Inicio",
      "OP2": "Servicios",
      "OP3": "Formulario de Contacto",
      "OP4": "Más Opciones",
      "OP4.1": "Sub Opción 1",
      "OP4.2": "Equipo",
      "OP4.3": "Sobre Nosotros",
      "CONTACT_US": "Contáctenos",
      "CLIENT_AREA": "Área del Cliente",
      "COMPANY": "Empresa Ltd"
    }
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'pt', // default language set to Portuguese
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;
