// src/TSservices.js
import i18n from 'i18next';

const resources = {
  en: {
    SERVICES_TITLE: "Our Services",
    SERVICES_WELCOME: "Welcome to our services page. Here, you will find a list of all the services we offer to our clients.",
    CLOUD_SERVICES_TITLE: "Cloud Services",
    CLOUD_SERVICES_DESCRIPTION: "Unlock the power of the cloud with our comprehensive services. We support all major public cloud providers, including AWS, Azure, Google Cloud, and more. Whether you're looking to migrate, manage, or optimize your cloud infrastructure, we have the expertise to help you succeed.",
    SERVER_SOLUTIONS_TITLE: "On-Premises Server Solutions",
    SERVER_SOLUTIONS_DESCRIPTION: "Ensure your on-premises infrastructure is robust and secure. Our team specializes in the installation, configuration, administration, and maintenance of Windows and Linux servers. We provide tailored solutions to meet the unique needs of your business, ensuring optimal performance and reliability.",
    HOSTING_SERVICES_TITLE: "Web & App Hosting",
    HOSTING_SERVICES_DESCRIPTION: "Host your website or application with confidence. We offer reliable and scalable hosting solutions, ensuring your digital presence is always online and performing at its best. From web hosting to app hosting, we provide the support you need to thrive in a digital world.",
    WEB_DESIGN_TITLE: "Web Design",
    WEB_DESIGN_DESCRIPTION: "Create stunning and user-friendly websites with our expert web design services. Our team combines aesthetics with functionality to deliver websites that not only look great but also provide an intuitive user experience. Whether you need a simple site or a complex web application, we've got you covered.",
    DATABASE_SERVICES_TITLE: "Database Services",
    DATABASE_SERVICES_DESCRIPTION: "Manage your data with ease. We offer database creation, administration, and maintenance services for MySQL, MariaDB, MongoDB, and other NoSQL databases. Our experts ensure your data is organized, secure, and accessible, supporting your business's data-driven decisions."
  },
  pt: {
    SERVICES_TITLE: "Nossos Serviços",
    SERVICES_WELCOME: "Bem-vindo à nossa página de serviços. Aqui, você encontrará uma lista de todos os serviços que oferecemos aos nossos clientes.",
    CLOUD_SERVICES_TITLE: "Serviços de Nuvem",
    CLOUD_SERVICES_DESCRIPTION: "Desbloqueie o poder da nuvem com nossos serviços abrangentes. Suportamos todos os principais provedores de nuvem pública, incluindo AWS, Azure, Google Cloud e mais. Quer você esteja procurando migrar, gerenciar ou otimizar sua infraestrutura em nuvem, temos a experiência para ajudá-lo a ter sucesso.",
    SERVER_SOLUTIONS_TITLE: "Soluções de Servidor Local",
    SERVER_SOLUTIONS_DESCRIPTION: "Garanta que sua infraestrutura local seja robusta e segura. Nossa equipe se especializa na instalação, configuração, administração e manutenção de servidores Windows e Linux. Fornecemos soluções personalizadas para atender às necessidades únicas do seu negócio, garantindo desempenho e confiabilidade ideais.",
    HOSTING_SERVICES_TITLE: "Hospedagem de Sites e Aplicativos",
    HOSTING_SERVICES_DESCRIPTION: "Hospede seu site ou aplicativo com confiança. Oferecemos soluções de hospedagem confiáveis e escaláveis, garantindo que sua presença digital esteja sempre online e funcionando da melhor forma. Desde hospedagem de sites até hospedagem de aplicativos, fornecemos o suporte necessário para prosperar no mundo digital.",
    WEB_DESIGN_TITLE: "Design de Sites",
    WEB_DESIGN_DESCRIPTION: "Crie sites impressionantes e fáceis de usar com nossos serviços especializados de design de sites. Nossa equipe combina estética com funcionalidade para entregar sites que não só parecem ótimos, mas também proporcionam uma experiência intuitiva para o usuário. Quer você precise de um site simples ou de uma aplicação web complexa, nós temos a solução.",
    DATABASE_SERVICES_TITLE: "Serviços de Banco de Dados",
    DATABASE_SERVICES_DESCRIPTION: "Gerencie seus dados com facilidade. Oferecemos serviços de criação, administração e manutenção de bancos de dados para MySQL, MariaDB, MongoDB e outros bancos de dados NoSQL. Nossos especialistas garantem que seus dados sejam organizados, seguros e acessíveis, apoiando as decisões orientadas por dados do seu negócio."
  },
  fr: {
    SERVICES_TITLE: "Nos Services",
    SERVICES_WELCOME: "Bienvenue sur notre page de services. Ici, vous trouverez une liste de tous les services que nous offrons à nos clients.",
    CLOUD_SERVICES_TITLE: "Services Cloud",
    CLOUD_SERVICES_DESCRIPTION: "Libérez le pouvoir du cloud avec nos services complets. Nous prenons en charge tous les principaux fournisseurs de cloud public, y compris AWS, Azure, Google Cloud et plus encore. Que vous cherchiez à migrer, gérer ou optimiser votre infrastructure cloud, nous avons l'expertise pour vous aider à réussir.",
    SERVER_SOLUTIONS_TITLE: "Solutions de Serveurs Sur Site",
    SERVER_SOLUTIONS_DESCRIPTION: "Assurez-vous que votre infrastructure sur site est robuste et sécurisée. Notre équipe se spécialise dans l'installation, la configuration, l'administration et la maintenance des serveurs Windows et Linux. Nous fournissons des solutions sur mesure pour répondre aux besoins uniques de votre entreprise, garantissant des performances optimales et une fiabilité maximale.",
    HOSTING_SERVICES_TITLE: "Hébergement de Sites Web et d'Applications",
    HOSTING_SERVICES_DESCRIPTION: "Hébergez votre site Web ou votre application en toute confiance. Nous offrons des solutions d'hébergement fiables et évolutives, garantissant que votre présence numérique est toujours en ligne et fonctionne de manière optimale. Qu'il s'agisse d'hébergement Web ou d'hébergement d'applications, nous fournissons le support dont vous avez besoin pour prospérer dans un monde numérique.",
    WEB_DESIGN_TITLE: "Conception de Sites Web",
    WEB_DESIGN_DESCRIPTION: "Créez des sites Web magnifiques et conviviaux avec nos services experts de conception de sites Web. Notre équipe combine esthétique et fonctionnalité pour offrir des sites Web non seulement attrayants, mais aussi intuitifs pour les utilisateurs. Que vous ayez besoin d'un site simple ou d'une application Web complexe, nous avons ce qu'il vous faut.",
    DATABASE_SERVICES_TITLE: "Services de Base de Données",
    DATABASE_SERVICES_DESCRIPTION: "Gérez vos données en toute simplicité. Nous offrons des services de création, d'administration et de maintenance de bases de données pour MySQL, MariaDB, MongoDB et autres bases de données NoSQL. Nos experts garantissent que vos données sont organisées, sécurisées et accessibles, soutenant les décisions axées sur les données de votre entreprise."
  },
  es: {
    SERVICES_TITLE: "Nuestros Servicios",
    SERVICES_WELCOME: "Bienvenido a nuestra página de servicios. Aquí encontrará una lista de todos los servicios que ofrecemos a nuestros clientes.",
    CLOUD_SERVICES_TITLE: "Servicios en la Nube",
    CLOUD_SERVICES_DESCRIPTION: "Libere el poder de la nube con nuestros servicios integrales. Soportamos todos los principales proveedores de nube pública, incluyendo AWS, Azure, Google Cloud y más. Ya sea que esté buscando migrar, gestionar u optimizar su infraestructura en la nube, tenemos la experiencia para ayudarlo a tener éxito.",
    SERVER_SOLUTIONS_TITLE: "Soluciones de Servidor en el Sitio",
    SERVER_SOLUTIONS_DESCRIPTION: "Asegúrese de que su infraestructura en el sitio sea robusta y segura. Nuestro equipo se especializa en la instalación, configuración, administración y mantenimiento de servidores Windows y Linux. Proporcionamos soluciones personalizadas para satisfacer las necesidades únicas de su negocio, garantizando un rendimiento y una fiabilidad óptimos.",
    HOSTING_SERVICES_TITLE: "Alojamiento de Sitios Web y Aplicaciones",
    HOSTING_SERVICES_DESCRIPTION: "Aloje su sitio web o aplicación con confianza. Ofrecemos soluciones de alojamiento fiables y escalables, asegurando que su presencia digital esté siempre en línea y funcionando de la mejor manera. Desde el alojamiento de sitios web hasta el alojamiento de aplicaciones, proporcionamos el soporte que necesita para prosperar en un mundo digital.",
    WEB_DESIGN_TITLE: "Diseño Web",
    WEB_DESIGN_DESCRIPTION: "Cree sitios web impresionantes y fáciles de usar con nuestros servicios de diseño web expertos. Nuestro equipo combina estética y funcionalidad para ofrecer sitios web que no solo se ven bien, sino que también brindan una experiencia de usuario intuitiva. Ya sea que necesite un sitio simple o una aplicación web compleja, tenemos lo que necesita.",
    DATABASE_SERVICES_TITLE: "Servicios de Base de Datos",
    DATABASE_SERVICES_DESCRIPTION: "Gestione sus datos con facilidad. Ofrecemos servicios de creación, administración y mantenimiento de bases de datos para MySQL, MariaDB, MongoDB y otras bases de datos NoSQL. Nuestros expertos aseguran que sus datos estén organizados, seguros y accesibles, apoyando las decisiones basadas en datos de su negocio."
  },
  de: {
    SERVICES_TITLE: "Unsere Dienstleistungen",
    SERVICES_WELCOME: "Willkommen auf unserer Serviceseite. Hier finden Sie eine Liste aller Dienstleistungen, die wir unseren Kunden anbieten.",
    CLOUD_SERVICES_TITLE: "Cloud-Dienste",
    CLOUD_SERVICES_DESCRIPTION: "Nutzen Sie die Leistung der Cloud mit unseren umfassenden Dienstleistungen. Wir unterstützen alle großen Anbieter öffentlicher Cloud-Dienste, darunter AWS, Azure, Google Cloud und mehr. Egal, ob Sie migrieren, verwalten oder Ihre Cloud-Infrastruktur optimieren möchten, wir haben das Fachwissen, um Ihnen zum Erfolg zu verhelfen.",
    SERVER_SOLUTIONS_TITLE: "On-Premises Serverlösungen",
    SERVER_SOLUTIONS_DESCRIPTION: "Stellen Sie sicher, dass Ihre On-Premises-Infrastruktur robust und sicher ist. Unser Team ist auf die Installation, Konfiguration, Verwaltung und Wartung von Windows- und Linux-Servern spezialisiert. Wir bieten maßgeschneiderte Lösungen, um die einzigartigen Anforderungen Ihres Unternehmens zu erfüllen und optimale Leistung und Zuverlässigkeit zu gewährleisten.",
    HOSTING_SERVICES_TITLE: "Web- und App-Hosting",
    HOSTING_SERVICES_DESCRIPTION: "Hosten Sie Ihre Website oder Anwendung mit Vertrauen. Wir bieten zuverlässige und skalierbare Hosting-Lösungen, die sicherstellen, dass Ihre digitale Präsenz immer online und optimal funktioniert. Von der Web-Hosting bis zur App-Hosting bieten wir die Unterstützung, die Sie benötigen, um in der digitalen Welt erfolgreich zu sein.",
    WEB_DESIGN_TITLE: "Webdesign",
    WEB_DESIGN_DESCRIPTION: "Erstellen Sie beeindruckende und benutzerfreundliche Websites mit unseren professionellen Webdesign-Dienstleistungen. Unser Team kombiniert Ästhetik und Funktionalität, um Websites zu erstellen, die nicht nur gut aussehen, sondern auch eine intuitive Benutzererfahrung bieten. Ob Sie eine einfache Website oder eine komplexe Webanwendung benötigen, wir haben die Lösung.",
    DATABASE_SERVICES_TITLE: "Datenbankdienste",
    DATABASE_SERVICES_DESCRIPTION: "Verwalten Sie Ihre Daten mit Leichtigkeit. Wir bieten Dienste zur Erstellung, Verwaltung und Wartung von Datenbanken für MySQL, MariaDB, MongoDB und andere NoSQL-Datenbanken an. Unsere Experten sorgen dafür, dass Ihre Daten organisiert, sicher und zugänglich sind, und unterstützen die datengestützten Entscheidungen Ihres Unternehmens."
  }
};

// Adding these translations to the main i18n instance
Object.keys(resources).forEach(lang => {
  i18n.addResourceBundle(lang, 'services', resources[lang], true, true);
});
