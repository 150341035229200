// src/Team.js
import React from 'react';
import { useTranslation } from 'react-i18next';
import './Team.css';
import './TSTeam'; // Import the translations

const Team = () => {
  const { t } = useTranslation('team');

  const teamMembers = [
    {
      name: 'John Doe',
      title: t('team.teamMember1.title'),
      description: t('team.teamMember1.description'),
      linkedIn: '#',
      email: 'mailto:example@example.com'
    },
    {
      name: 'Jane Smith',
      title: t('team.teamMember2.title'),
      description: t('team.teamMember2.description'),
      linkedIn: '#',
      email: 'mailto:example@example.com'
    },
    {
      name: 'Alice Johnson',
      title: t('team.teamMember3.title'),
      description: t('team.teamMember3.description'),
      linkedIn: '#',
      email: 'mailto:example@example.com'
    }
  ];

  return (
    <div className="team-container">
      {teamMembers.map((member, index) => (
        <div className="team-card" key={index}>
          <img src={process.env.PUBLIC_URL + '/IMG/1041778.png'} alt={member.name} className="team-image" />
          <div className="team-info">
            <h3>{member.name}</h3>
            <h4>{member.title}</h4>
            <p>{member.description}</p>
            <div className="team-socials">
              <a href={member.linkedIn} aria-label="LinkedIn">
                <img src={process.env.PUBLIC_URL + '/IMG/linkedin.png'} alt='LINKD' />  
              </a>
              <a href={member.email} aria-label="Email">
                <img src={process.env.PUBLIC_URL + '/IMG/mail.png'} alt="Email" />
              </a>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Team;
