// src/Overlay.js
import React from 'react';
import './Overlay.css';

const Overlay = ({ isVisible, onClose }) => {
  if (!isVisible) return null;

  return (
    <div className="overlay" onClick={onClose}>
      <div className="overlay-content" onClick={(e) => e.stopPropagation()}>
        <button className="close-button" onClick={onClose}>X</button>
        <h2>Contact Information</h2>
        <p className="contact-number">+351 800 500 252</p>
        <div className="contact-options">
          <a href="https://api.whatsapp.com/send?phone=351926841701&text=Boa%20tarde%20T%C3%A9cnicos.%20Gostaria%20de%20saber%20mais%20informações" target="_blank" rel="noopener noreferrer" className="contact-option">
            <img src={process.env.PUBLIC_URL + '/IMG/WhatsApp_logo.png'} alt='Whatsapp' />  
          </a>
          <a href="https://t.me/+gGjd-C7TBrIyNDY0" target="_blank" rel="noopener noreferrer" className="contact-option">
            <img src={process.env.PUBLIC_URL + '/IMG/Telegram-logo.png'} alt='Telegram' />  
          </a>
          <a href="https://discord.gg/ReyMJkdE4Y" target="_blank" rel="noopener noreferrer" className="contact-option">
            <img src={process.env.PUBLIC_URL + '/IMG/discord.png'} alt='Discord' />  
          </a>
        </div>
      </div>
    </div>
  );
};

export default Overlay;
