// src/TSTeam.js
import i18n from 'i18next';

const resources = {
  en: {
    team: {
      teamMember1: {
        title: 'System Administrator',
        description: 'System Administrator with about 10 years of experience, expert in hardware and Microsoft systems.'
      },
      teamMember2: {
        title: 'Software Engineer',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
      },
      teamMember3: {
        title: 'Project Manager',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
      }
    }
  },
  pt: {
    team: {
      teamMember1: {
        title: 'Administrador de Sistemas',
        description: 'Administrador de Sistemas, cerca de 10 anos de experiencia, expert em hardware e sistemas Microsoft.'
      },
      teamMember2: {
        title: 'Engenheira de Software',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
      },
      teamMember3: {
        title: 'Gerente de Projeto',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
      }
    }
  },
  fr: {
    team: {
      teamMember1: {
        title: 'Administrateur Systèmes',
        description: 'Administrateur systèmes avec environ 10 ans d\'expérience, expert en matériel et systèmes Microsoft.'
      },
      teamMember2: {
        title: 'Ingénieure Logiciel',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
      },
      teamMember3: {
        title: 'Chef de Projet',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
      }
    }
  },
  es: {
    team: {
      teamMember1: {
        title: 'Administrador de Sistemas',
        description: 'Administrador de Sistemas con aproximadamente 10 años de experiencia, experto en hardware y sistemas Microsoft.'
      },
      teamMember2: {
        title: 'Ingeniera de Software',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
      },
      teamMember3: {
        title: 'Gerente de Proyecto',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
      }
    }
  },
  de: {
    team: {
      teamMember1: {
        title: 'Systemadministrator',
        description: 'Systemadministrator mit etwa 10 Jahren Erfahrung, Experte für Hardware und Microsoft-Systeme.'
      },
      teamMember2: {
        title: 'Software-Ingenieurin',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
      },
      teamMember3: {
        title: 'Projektmanagerin',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
      }
    }
  }
};

Object.keys(resources).forEach(lang => {
  i18n.addResourceBundle(lang, 'team', resources[lang], true, true);
});

export default i18n;
