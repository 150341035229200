// src/TSabout.js
import i18n from 'i18next';

const resources = {
  en: {
    ABOUT_US_TITLE: "About Us",
    ABOUT_US_CONTENT: "Founded in 2010, our IT company has been dedicated to providing top-notch IT solutions to businesses of all sizes. Our goal is to help companies streamline their operations, improve efficiency, and achieve their business objectives through the effective use of technology. With a team of experienced professionals, we offer a wide range of services including software development, network management, cybersecurity, and IT consulting. We pride ourselves on our ability to deliver customized solutions that meet the unique needs of each of our clients. Our mission is to be a trusted partner for our clients, helping them navigate the ever-changing landscape of technology and achieve success in their respective industries. We believe in building long-term relationships based on trust, integrity, and excellence.",
    IMAGE_BRANDING_TITLE: "Image and Branding",
    IMAGE_BRANDING_CONTENT: "Our company's image and branding reflect our commitment to excellence and innovation. Our visual identity, from the logo to our marketing materials, conveys our values and mission. We continually strive to present a professional and consistent image that resonates with our clients and partners.",
    BUSINESS_MARKETING_PLAN_TITLE: "Business and Marketing Plan",
    BUSINESS_MARKETING_PLAN_CONTENT: "Our business and marketing plan is centered around growth, sustainability, and delivering value to our clients. We set clear objectives and strategies to ensure our company's long-term success. By focusing on innovation, customer satisfaction, and operational efficiency, we aim to achieve our goals and support our clients in achieving theirs.",
    LINK_TO_BRAND_GUIDE: "Link to Brand Guide",
    LINK_TO_BUSINESS_PLAN: "Link to Business Plan"
  },
  pt: {
    ABOUT_US_TITLE: "Sobre Nós",
    ABOUT_US_CONTENT: "Fundada em 2010, nossa empresa de TI tem se dedicado a fornecer soluções de TI de alta qualidade para empresas de todos os tamanhos. Nosso objetivo é ajudar as empresas a otimizar suas operações, melhorar a eficiência e alcançar seus objetivos de negócios por meio do uso eficaz da tecnologia. Com uma equipe de profissionais experientes, oferecemos uma ampla gama de serviços, incluindo desenvolvimento de software, gerenciamento de rede, cibersegurança e consultoria de TI. Nos orgulhamos de nossa capacidade de oferecer soluções personalizadas que atendem às necessidades exclusivas de cada um de nossos clientes. Nossa missão é ser um parceiro confiável para nossos clientes, ajudando-os a navegar no cenário tecnológico em constante mudança e alcançar o sucesso em seus respectivos setores. Acreditamos em construir relacionamentos de longo prazo baseados em confiança, integridade e excelência.",
    IMAGE_BRANDING_TITLE: "Imagem e Branding",
    IMAGE_BRANDING_CONTENT: "A imagem e o branding da nossa empresa refletem nosso compromisso com a excelência e inovação. Nossa identidade visual, desde o logotipo até nossos materiais de marketing, transmite nossos valores e missão. Continuamente nos esforçamos para apresentar uma imagem profissional e consistente que ressoe com nossos clientes e parceiros.",
    BUSINESS_MARKETING_PLAN_TITLE: "Plano de Negócios e Marketing",
    BUSINESS_MARKETING_PLAN_CONTENT: "Nosso plano de negócios e marketing é centrado no crescimento, sustentabilidade e entrega de valor aos nossos clientes. Definimos objetivos e estratégias claras para garantir o sucesso a longo prazo da nossa empresa. Focando em inovação, satisfação do cliente e eficiência operacional, buscamos alcançar nossos objetivos e apoiar nossos clientes em alcançar os deles.",
    LINK_TO_BRAND_GUIDE: "Link para Guia de Marca",
    LINK_TO_BUSINESS_PLAN: "Link para Plano de Negócios"
  },
  fr: {
    ABOUT_US_TITLE: "À Propos de Nous",
    ABOUT_US_CONTENT: "Fondée en 2010, notre entreprise de TI s'est engagée à fournir des solutions de TI de premier ordre aux entreprises de toutes tailles. Notre objectif est d'aider les entreprises à rationaliser leurs opérations, à améliorer leur efficacité et à atteindre leurs objectifs commerciaux grâce à l'utilisation efficace de la technologie. Avec une équipe de professionnels expérimentés, nous offrons une large gamme de services, y compris le développement de logiciels, la gestion de réseaux, la cybersécurité et la consultation en TI. Nous sommes fiers de notre capacité à fournir des solutions personnalisées qui répondent aux besoins uniques de chacun de nos clients. Notre mission est d'être un partenaire de confiance pour nos clients, les aidant à naviguer dans le paysage technologique en constante évolution et à réussir dans leurs secteurs respectifs. Nous croyons en l'établissement de relations à long terme fondées sur la confiance, l'intégrité et l'excellence.",
    IMAGE_BRANDING_TITLE: "Image et Branding",
    IMAGE_BRANDING_CONTENT: "L'image et le branding de notre entreprise reflètent notre engagement envers l'excellence et l'innovation. Notre identité visuelle, du logo à nos supports marketing, transmet nos valeurs et notre mission. Nous nous efforçons continuellement de présenter une image professionnelle et cohérente qui résonne avec nos clients et partenaires.",
    BUSINESS_MARKETING_PLAN_TITLE: "Plan d'Affaires et de Marketing",
    BUSINESS_MARKETING_PLAN_CONTENT: "Notre plan d'affaires et de marketing est centré sur la croissance, la durabilité et la création de valeur pour nos clients. Nous définissons des objectifs et des stratégies clairs pour assurer le succès à long terme de notre entreprise. En mettant l'accent sur l'innovation, la satisfaction du client et l'efficacité opérationnelle, nous visons à atteindre nos objectifs et à aider nos clients à atteindre les leurs.",
    LINK_TO_BRAND_GUIDE: "Lien vers le Guide de Marque",
    LINK_TO_BUSINESS_PLAN: "Lien vers le Plan d'Affaires"
},
  de: {
    ABOUT_US_TITLE: "Über Uns",
    ABOUT_US_CONTENT: "Gegründet im Jahr 2010, hat sich unser IT-Unternehmen der Bereitstellung erstklassiger IT-Lösungen für Unternehmen jeder Größe verschrieben. Unser Ziel ist es, Unternehmen dabei zu helfen, ihre Abläufe zu optimieren, die Effizienz zu steigern und ihre Geschäftsziele durch den effektiven Einsatz von Technologie zu erreichen. Mit einem Team erfahrener Fachleute bieten wir eine breite Palette von Dienstleistungen an, darunter Softwareentwicklung, Netzwerkmanagement, Cybersicherheit und IT-Beratung. Wir sind stolz auf unsere Fähigkeit, maßgeschneiderte Lösungen zu liefern, die den einzigartigen Bedürfnissen jedes unserer Kunden entsprechen. Unsere Mission ist es, ein vertrauenswürdiger Partner für unsere Kunden zu sein, der ihnen hilft, sich in der sich ständig verändernden Technologielandschaft zurechtzufinden und in ihren jeweiligen Branchen erfolgreich zu sein. Wir glauben daran, langfristige Beziehungen aufzubauen, die auf Vertrauen, Integrität und Exzellenz basieren.",
    IMAGE_BRANDING_TITLE: "Image und Branding",
    IMAGE_BRANDING_CONTENT: "Das Image und Branding unseres Unternehmens spiegeln unser Engagement für Exzellenz und Innovation wider. Unsere visuelle Identität, vom Logo bis zu unseren Marketingmaterialien, vermittelt unsere Werte und Mission. Wir streben kontinuierlich danach, ein professionelles und konsistentes Image zu präsentieren, das bei unseren Kunden und Partnern Anklang findet.",
    BUSINESS_MARKETING_PLAN_TITLE: "Geschäfts- und Marketingplan",
    BUSINESS_MARKETING_PLAN_CONTENT: "Unser Geschäfts- und Marketingplan konzentriert sich auf Wachstum, Nachhaltigkeit und die Schaffung von Mehrwert für unsere Kunden. Wir setzen klare Ziele und Strategien, um den langfristigen Erfolg unseres Unternehmens sicherzustellen. Durch die Fokussierung auf Innovation, Kundenzufriedenheit und operative Effizienz streben wir an, unsere Ziele zu erreichen und unsere Kunden dabei zu unterstützen, ihre eigenen Ziele zu erreichen.",
    LINK_TO_BRAND_GUIDE: "Link zum Markenleitfaden",
    LINK_TO_BUSINESS_PLAN: "Link zum Geschäftsplan"
  },
  es: {
    ABOUT_US_TITLE: "Sobre Nosotros",
    ABOUT_US_CONTENT: "Fundada en 2010, nuestra empresa de TI se ha dedicado a proporcionar soluciones de TI de alta calidad a empresas de todos los tamaños. Nuestro objetivo es ayudar a las empresas a optimizar sus operaciones, mejorar la eficiencia y alcanzar sus objetivos comerciales mediante el uso efectivo de la tecnología. Con un equipo de profesionales experimentados, ofrecemos una amplia gama de servicios que incluyen desarrollo de software, gestión de redes, ciberseguridad y consultoría de TI. Nos enorgullecemos de nuestra capacidad para ofrecer soluciones personalizadas que satisfacen las necesidades únicas de cada uno de nuestros clientes. Nuestra misión es ser un socio de confianza para nuestros clientes, ayudándolos a navegar en el cambiante panorama tecnológico y lograr el éxito en sus respectivos sectores. Creemos en construir relaciones a largo plazo basadas en la confianza, la integridad y la excelencia.",
    IMAGE_BRANDING_TITLE: "Imagen y Branding",
    IMAGE_BRANDING_CONTENT: "La imagen y el branding de nuestra empresa reflejan nuestro compromiso con la excelencia y la innovación. Nuestra identidad visual, desde el logotipo hasta nuestros materiales de marketing, transmite nuestros valores y misión. Nos esforzamos continuamente por presentar una imagen profesional y coherente que resuene con nuestros clientes y socios.",
    BUSINESS_MARKETING_PLAN_TITLE: "Plan de Negocios y Marketing",
    BUSINESS_MARKETING_PLAN_CONTENT: "Nuestro plan de negocios y marketing se centra en el crecimiento, la sostenibilidad y la entrega de valor a nuestros clientes. Establecemos objetivos y estrategias claras para asegurar el éxito a largo plazo de nuestra empresa. Al centrarnos en la innovación, la satisfacción del cliente y la eficiencia operativa, buscamos alcanzar nuestros objetivos y apoyar a nuestros clientes en la consecución de los suyos.",
    LINK_TO_BRAND_GUIDE: "Enlace al Guía de Marca",
    LINK_TO_BUSINESS_PLAN: "Enlace al Plan de Negocios"
}
};

// Adding these translations to the main i18n instance
Object.keys(resources).forEach(lang => {
  i18n.addResourceBundle(lang, 'about', resources[lang], true, true);
});
