import React from 'react';
import './landing.css';

const Landing = () => {
  return (
    <div className="landing-container">
      <video autoPlay muted loop className="video-background">
        <source src={process.env.PUBLIC_URL + '/landing.mp4'} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="landing-content">
        <h1>Let's CONQUER your IT with our solutions</h1>
      </div>
    </div>
  );
};

export default Landing;
