// src/TSform.js
import i18n from 'i18next';

const resources = {
  en: {
    CONTACT_FORM_TITLE: "Contact Form",
    CONTACT_NAME_LABEL: "Contact Name",
    COMPANY_NAME_LABEL: "Company Name",
    EMAIL_LABEL: "Email",
    COUNTRY_LABEL: "Country",
    CONTACT_NUMBER_LABEL: "Contact Number",
    PREFIX_LABEL: "Prefix",
    SUBMIT_BUTTON: "Submit",
    SELECT_COUNTRY_PLACEHOLDER: "Select Country",
    PREFIX_PLACEHOLDER: "Prefix",
    FORM_SUBMISSION_SUCCESS: "Your message has been sent successfully!",
    FORM_SUBMISSION_ERROR: "An error occurred. Please try again later.",
    MESSAGE_LABEL: "Message"
  },
  pt: {
    CONTACT_FORM_TITLE: "Formulário de Contato",
    CONTACT_NAME_LABEL: "Nome de Contato",
    COMPANY_NAME_LABEL: "Nome da Empresa",
    EMAIL_LABEL: "Email",
    COUNTRY_LABEL: "País",
    CONTACT_NUMBER_LABEL: "Número de Contato",
    PREFIX_LABEL: "Prefixo",
    SUBMIT_BUTTON: "Enviar",
    SELECT_COUNTRY_PLACEHOLDER: "Selecionar País",
    PREFIX_PLACEHOLDER: "Prefixo",
    FORM_SUBMISSION_SUCCESS: "Sua mensagem foi enviada com sucesso!",
    FORM_SUBMISSION_ERROR: "Ocorreu um erro. Por favor, tente novamente mais tarde.",
    MESSAGE_LABEL: "Mensagem"
  },
  fr: {
    CONTACT_FORM_TITLE: "Formulaire de Contact",
    CONTACT_NAME_LABEL: "Nom du Contact",
    COMPANY_NAME_LABEL: "Nom de l'Entreprise",
    EMAIL_LABEL: "Email",
    COUNTRY_LABEL: "Pays",
    CONTACT_NUMBER_LABEL: "Numéro de Contact",
    PREFIX_LABEL: "Préfixe",
    SUBMIT_BUTTON: "Envoyer",
    SELECT_COUNTRY_PLACEHOLDER: "Sélectionner un pays",
    PREFIX_PLACEHOLDER: "Préfixe",
    FORM_SUBMISSION_SUCCESS: "Votre message a été envoyé avec succès !",
    FORM_SUBMISSION_ERROR: "Une erreur s'est produite. Veuillez réessayer plus tard.",
    MESSAGE_LABEL: "Message"
  },
  es: {
    CONTACT_FORM_TITLE: "Formulario de Contacto",
    CONTACT_NAME_LABEL: "Nombre de Contacto",
    COMPANY_NAME_LABEL: "Nombre de la Empresa",
    EMAIL_LABEL: "Correo Electrónico",
    COUNTRY_LABEL: "País",
    CONTACT_NUMBER_LABEL: "Número de Contacto",
    PREFIX_LABEL: "Prefijo",
    SUBMIT_BUTTON: "Enviar",
    SELECT_COUNTRY_PLACEHOLDER: "Seleccionar País",
    PREFIX_PLACEHOLDER: "Prefijo",
    FORM_SUBMISSION_SUCCESS: "¡Tu mensaje ha sido enviado con éxito!",
    FORM_SUBMISSION_ERROR: "Ocurrió un error. Por favor, inténtalo de nuevo más tarde.",
    MESSAGE_LABEL: "Mensaje"
  },
  de: {
    CONTACT_FORM_TITLE: "Kontaktformular",
    CONTACT_NAME_LABEL: "Kontaktname",
    COMPANY_NAME_LABEL: "Firmenname",
    EMAIL_LABEL: "E-Mail",
    COUNTRY_LABEL: "Land",
    CONTACT_NUMBER_LABEL: "Kontaktnummer",
    PREFIX_LABEL: "Präfix",
    SUBMIT_BUTTON: "Senden",
    SELECT_COUNTRY_PLACEHOLDER: "Land auswählen",
    PREFIX_PLACEHOLDER: "Präfix",
    FORM_SUBMISSION_SUCCESS: "Ihre Nachricht wurde erfolgreich gesendet!",
    FORM_SUBMISSION_ERROR: "Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.",
    MESSAGE_LABEL: "Nachricht"
  }
};

// Adding these translations to the main i18n instance
Object.keys(resources).forEach(lang => {
  i18n.addResourceBundle(lang, 'form', resources[lang], true, true);
});
