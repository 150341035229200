import React, { useState, useEffect } from 'react';
import './App.css';
import i18n from './i18n';
import { useTranslation } from 'react-i18next';
import Services from './Services';
import ContactForm from './ContactForm';
import Client from './Client';
import Team from './Team';
import AboutUS from './AboutUs';
import Overlay from './Overlay';
import Landing from './landing';

function App() {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [languageDropdownOpen, setLanguageDropdownOpen] = useState(false);
  const [currentComponent, setCurrentComponent] = useState('home');
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  const { t } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setLanguageDropdownOpen(false);
  };

  useEffect(() => {
    // Handle side-effects if necessary when language changes
  }, [i18n.language]);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  const toggleDropdown = (event) => {
    event.stopPropagation();
    setDropdownOpen((prev) => !prev);
  };

  const toggleLanguageDropdown = (event) => {
    event.stopPropagation();
    setLanguageDropdownOpen((prev) => !prev);
  };

  const renderComponent = () => {
    switch (currentComponent) {
      case 'services':
        return <Services />;
      case 'contact':
        return <ContactForm />;
      case 'client':
        return <Client />;
      case 'team':
        return <Team />;
      case 'about':
        return <AboutUS />;
      default:
        return <Landing />;
    }
  };

  const languageFlags = {
    pt: { src: 'https://flagcdn.com/16x12/pt.png', label: 'Português' },
    en: { src: 'https://flagcdn.com/16x12/us.png', label: 'English' },
    es: { src: 'https://flagcdn.com/16x12/es.png', label: 'Español' },
    fr: { src: 'https://flagcdn.com/16x12/fr.png', label: 'Français' },
    de: { src: 'https://flagcdn.com/16x12/de.png', label: 'Deutsch' }
  };

  const handleContactClick = (event) => {
    if (window.innerWidth > 768) { // Only show overlay on desktop
      event.preventDefault();
      setIsOverlayVisible(true);
    }
  };

  const closeOverlay = () => {
    setIsOverlayVisible(false);
  };

  return (
    <div className="App" onClick={() => { setDropdownOpen(false); setLanguageDropdownOpen(false); }}>
      <div className="navbar">
        <div className="logo">
          <img src={process.env.PUBLIC_URL + '/IMG/logotipo.webp'} alt='LOGO' />
        </div>
        <button className="hamburger-menu" onClick={toggleMenu} aria-label={t('Toggle menu')} aria-expanded={menuOpen}>
          <div></div>
          <div></div>
          <div></div>
        </button>
        <div className={`menu ${menuOpen ? 'show' : ''}`}>
          <div className="menu-item" onClick={() => { setCurrentComponent('home'); closeMenu(); }} tabIndex={0}>{t('OP1')}</div>
          <div className="menu-item" onClick={() => { setCurrentComponent('services'); closeMenu(); }} tabIndex={0} role="button">{t('OP2')}</div>
          <div className="menu-item" onClick={() => { setCurrentComponent('contact'); closeMenu(); }} tabIndex={0}>{t('OP3')}</div>
          <div className="menu-item op4" tabIndex={0} aria-haspopup="true" aria-expanded={dropdownOpen} onClick={(e) => toggleDropdown(e)}>
            {t('OP4')}
            <div className={`dropdown-content ${dropdownOpen ? 'show' : ''}`}>
              <div className="dropdown-item" tabIndex={0} onClick={closeMenu}>{t('OP4.1')}</div>
              <div className="dropdown-item" onClick={() => { setCurrentComponent('team'); closeMenu(); }} tabIndex={0} role="button">{t('OP4.2')}</div>
              <div className="dropdown-item" onClick={() => { setCurrentComponent('about'); closeMenu(); }} tabIndex={0} role="button">{t('OP4.3')}</div>
            </div>
          </div>
        </div>
        <div className="client-link" onClick={() => { setCurrentComponent('client'); closeMenu(); }} tabIndex={0} role="button">{t('CLIENT_AREA')}</div>
        <div className="language-dropdown" onClick={(e) => toggleLanguageDropdown(e)} aria-haspopup="true" aria-expanded={languageDropdownOpen}>
          <div className="dropdown-title">
            <img
              src={languageFlags[i18n.language]?.src || languageFlags.en.src}
              alt={t(i18n.language) || 'Language'}
              width="24"
            />
          </div>
          <div className={`dropdown-content ${languageDropdownOpen ? 'show' : ''}`}>
            {Object.entries(languageFlags).map(([lang, { src, label }]) => (
              <div className="dropdown-item" key={lang} onClick={() => { changeLanguage(lang); closeMenu(); }} tabIndex={0}>
                <img src={src} alt={label} width="24" /> {label}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="content">
        {renderComponent()}
      </div>
      <Footer onContactClick={handleContactClick} />
      <Overlay isVisible={isOverlayVisible} onClose={closeOverlay} />
    </div>
  );
}

function Footer({ onContactClick }) {
  const { t } = useTranslation();
  return (
    <div className="footer">
      <a href="tel:+351800500252" className="contact-button" role="button" aria-label={t('Contact us')} onClick={onContactClick}>{t('CONTACT_US')}</a>
      <div className="social">
        <a href="https://www.linkedin.com/company/conquer-it/" className="icon" aria-label="LinkedIn">
          <img src={process.env.PUBLIC_URL + '/IMG/linked-ico.png'} alt='LINKD' />
        </a>
        <a href="#" className="icon" aria-label="GitHub">
          <img src={process.env.PUBLIC_URL + '/IMG/git-ico.png'} alt='GIT' />
        </a>
        <a href="https://www.facebook.com/conquerit.pt" className="icon" aria-label="Facebook">
          <img src={process.env.PUBLIC_URL + '/IMG/FB-ico.png'} alt='FB' />
        </a>
        <a href="https://www.instagram.com/conquer_it.pt" className="icon" aria-label="Instagram">
          <img src={process.env.PUBLIC_URL + '/IMG/insta-ico.png'} alt='Insta' />
        </a>
        <a href="#" className="icon" aria-label="Twitter">
          <img src={process.env.PUBLIC_URL + '/IMG/twit-ico.png'} alt='TWIT' />
        </a>
      </div>
      <div className="company" tabIndex={0}>{t('COMPANY')}</div>
    </div>
  );
}

export default App;
